<template>
  <transition>
    <footer
      v-if="acf_options"
      id="site-footer"
      class="site-footer">
      <div class="gutter-wrapper">
        <div class="footer-container">
          <div class="footer-head">
            <div class="left">
              <div class="left-inner">
                <div class="logo-container">
                  <div class="image-container">
                    <router-link
                      :to="'/'"
                      class="footer-logo"
                      title="Home">
                      <img
                        v-if="acf_options.site_logo_footer"
                        class="h-auto w-full max-w-[70px]"
                        :src="acf_options.site_logo_footer.url"
                        :alt="acf_options.site_logo_footer.alt" />
                    </router-link>
                  </div>
                </div>

                <div
                  class="directions-container"
                  v-html="acf_options.footer_direction" />

                <div class="social-container mobile">
                  <a
                    v-if="acf_options.linkedin"
                    :href="acf_options.linkedin"
                    target="_blank">
                    <img src="@/assets/img/linkedin_logo_white.png" />
                  </a>

                  <a
                    v-if="acf_options.x"
                    :href="acf_options.x"
                    target="_blank">
                    <img src="@/assets/img/x_logo_white.png" />
                  </a>
                </div>
              </div>

              <div class="left-right">
                <div class="menus-container">
                  <div class="footer-col">
                    <FooterMenu name="footer-menu" />
                  </div>
                </div>
              </div>
            </div>

            <div class="right">
              <div
                class="content"
                v-html="acf_options.footer_emails" />
            </div>
          </div>

          <div class="footer-body">
            <p class="rights">
              <template
                v-for="(link, indx) of acf_options.footer_links"
                :key="`footer-link-${indx}`">
                <a
                  :href="link.link.url"
                  :target="link.link.target"
                  class="mr-1"
                  >{{ link.link.title }}</a
                >
                {{ indx < acf_options.footer_links.length - 1 ? ' | ' : '' }}
              </template>
            </p>

            <p>Genesis Therapeutics ©{{ currentYear }}</p>

            <div class="social-container desktop">
              <a
                v-if="acf_options.linkedin"
                :href="acf_options.linkedin"
                target="_blank">
                <img src="@/assets/img/linkedin_logo_white.png" />
              </a>

              <a
                v-if="acf_options.x"
                :href="acf_options.x"
                target="_blank">
                <img src="@/assets/img/x_logo_white.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </transition>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from '@/stores/main'
import { ref, computed, onBeforeMount, watch } from 'vue'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import useSiteLoading from '@/composables/useSiteLoading'

const { siteLoading } = useSiteLoading()

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const acf_options = ref(null)

const currentYear = computed(() => {
  return new Date().getFullYear()
})

watch(siteLoading, () => {
  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 500)
})

onBeforeMount(async () => {
  acf_options.value = await store.acfOptions()
})
</script>

<style lang="scss">
.site-footer {
  @apply w-full mx-auto bg-genesis-blue text-white pt-14 pb-7 lg:pt-14 lg:pb-5 relative overflow-hidden;
}

.gutter-wrapper {
  @apply inner-container;

  a {
    &:hover {
      @apply text-genesis-jade transition-colors;

      svg {
        path {
          @apply fill-genesis-jade transition-all;
        }
      }
    }
  }
}

.footer-container {
  @apply divide-text;

  .social-container {
    @apply flex flex-row justify-end gap-5 py-0 relative w-1/2 lg:py-0;

    a {
      @apply mr-4 transition-opacity;

      &:hover {
        @apply opacity-65;
      }
    }

    &.mobile {
      @apply lg:hidden;
    }

    &.desktop {
      @apply hidden lg:flex lg:order-1 lg:justify-start;
    }
  }
}

.footer-head {
  @apply flex flex-col px-11 lg:flex-row lg:justify-between lg:pt-10 lg:pb-20 lg:px-5;

  .footer-logo {
    @apply h-fit;
  }

  .left {
    @apply flex flex-col lg:flex-row justify-center items-start lg:gap-28 lg:w-1/2 lg:justify-start;

    .left-inner {
      @apply flex flex-row justify-between flex-wrap gap-7 items-start w-full lg:w-auto lg:max-w-[290px] lg:justify-start lg:gap-7;

      .logo-container {
        @apply w-full lg:w-auto;

        .image-container {
          @apply w-fit;
        }
      }

      .directions-container {
        @apply flex-1;

        p {
          @apply text-[14px] mb-3 leading-[16px];

          &:last-child {
            @apply mb-0;
          }
        }
      }

      .social-container {
        &.mobile {
          @apply mt-1;
        }
      }
    }

    .left-right {
      .menus-container {
        @apply flex flex-col mt-12 mb-16 lg:my-0 lg:flex-row lg:gap-24 lg:items-start;
      }
    }
  }

  .right {
    @apply flex flex-col items-start pt-4 pb-8
    lg:flex-row lg:py-0 lg:w-1/2 lg:pl-10 lg:justify-end;

    .content {
      * {
        @apply text-genesis-platinum lg:text-right;

        a {
          &:hover {
            @apply text-genesis-jade;
          }
        }
      }
    }
  }
}

.footer-body {
  @apply text-[16px] relative w-full text-white px-11 flex flex-row justify-between pt-8 pb-0 lg:flex-row lg:justify-between lg:pb-0 lg:px-5;

  &:before {
    @apply content-[''] absolute left-11 right-11 top-0 h-[1px] bg-white lg:left-5 lg:right-5;
  }

  p {
    @apply text-[16px] lg:order-3;
  }

  .rights {
    @apply text-white text-left lg:order-2;
  }
}
</style>
