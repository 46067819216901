const routeList = window.__VUE_WORDPRESS__.routing.routes

const importedComponents = [
  {
    name: 'Blog',
  },
  {
    name: 'Page',
  },
  {
    name: '404Page',
  },
  {
    name: 'SingleTeam',
  },
  {
    name: 'SinglePost',
  },
  {
    name: 'SinglePosition',
  },
  {
    name: 'SingleTeam',
  },
]

for (var key in routeList) {
  let route = routeList[key]
  let component_name = route.component
  const component = importedComponents.find((x) => {
    return x.name === component_name
  })
  if (component)
    routeList[key].component = () =>
      import(`../components/${component_name}.vue`)
  else
    console.error('Developer: Please create component named ' + component_name)
}

const routes = routeList

export default routes
