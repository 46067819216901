<template>
  <div
    ref="intro"
    class="intro">
    <div class="intro-container">
      <DotLottieVue
        ref="animation"
        class="intro-animation"
        autoplay
        :src="introJson" />
    </div>
  </div>
</template>

<script setup>
import { DotLottieVue } from '@lottiefiles/dotlottie-vue'
import introJson from '@/assets/lottie/introfinal.lottie?url'
import useIntroShown from '@/composables/useIntroShown.js'
import { ref, onMounted } from 'vue'

const { setIntroComplete } = useIntroShown()
const animation = ref()

onMounted(() => {
  document.documentElement.classList.add('no-overflow')
  if (animation.value) {
    const dotLottie = animation.value.getDotLottieInstance()
    dotLottie.addEventListener('frame', ({ currentFrame }) => {
      if (currentFrame > 40) {
        dotLottie.pause()
        setTimeout(() => {
          setIntroComplete()
          document.documentElement.classList.remove('no-overflow')
        }, 500)
      }
    })
  }
})
</script>

<style lang="scss">
.intro {
  @apply fixed left-0 top-0 z-[51] h-full w-full bg-genesis-platinum;
  .intro-container {
    @apply relative left-0 flex h-[100vh] w-[100vw] items-center justify-center bg-cover bg-no-repeat;
    .intro-animation {
      @apply m-auto h-[40%] w-[40%] md:h-[300px] md:w-[300px];
      canvas {
        @apply relative top-[-50px];
      }
    }
  }
}
</style>
