import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import locoScroll from '../assets/js/lenisScroll'
import emitter from '@/scripts/emitter'
import VueBodyClass from 'vue-body-class'

const { site } = window.__VUE_WORDPRESS__.state

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      emitter.once('pageTransition', () => {
        if (to.hash) {
          setTimeout(() => {
            locoScroll.scrollTo(to.hash, {
              immediate: true,
              onComplete: () => {
                emitter.emit('scrolled')
                resolve()
              },
            })
          }, 500)
        } else {
          if (savedPosition) {
            setTimeout(() => {
              locoScroll.scrollTo(savedPosition.top, {
                immediate: true,
                onComplete: () => {
                  emitter.emit('scrolled')
                  resolve()
                },
              })
            }, 500)
          } else {
            locoScroll.scrollTo('top', {
              immediate: true,
              onComplete: () => {
                emitter.emit('scrolled')
                resolve()
              },
            })
          }
        }
      })
    })
  },
})

const vueBodyClass = new VueBodyClass(routes)

router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next)
})

router.afterEach((to) => {
  // set page title on route change
  window.document.title =
    `${to.meta.title} | ${site.name}` || `${site.name} | ${site.description}`
})
